const {areas} = require('./area.js')
const _ = require('lodash')
// 只到城市即可
// const areaCity = _.cloneDeep(areas) // 深拷贝一份，否则会影响其他地方的使用
// const map = {
//       "上海市":1,
//       "天津市":1,
//       "重庆市":1,
//       "北京市":1
//     }
//     areaCity.forEach(ele=>{
//       if (map[ele.label]>0){
//          ele.children = []
//       }
//       ele.children.forEach(city =>{
//          city.children = []
//        })
//     })

const areaCity = [
    {
        label: "北京市"
    },
    {
        label: "上海市"
    },
    {
        label: "广州市"
    },
    {
        label: "深圳市"
    },
    {
        label: "杭州市"
    },
    {
        label: "香港特别行政区"
    }
]

export {areaCity}
   
  