import { Button, Form, Input, message } from "antd"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { getTime } from "./../utils"
import { useAuth } from "./../utils/auth"
import "./Login.css"

export default function Login() {
  const history = useHistory()
  const { login } = useAuth()
  const [submitting, setSubmitting] = useState(false)
  const onFinish = async ({ username, password }) => {
    setSubmitting(true)
    try {
      await login(username, password)
      history.push("/events/list")
      message.success(`${getTime()}好！`)
    } catch (error) {
      console.error(error)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className="login">
      <div className="form-box shadow">
        <div className="flex justify-center mb-12">
          <img className="h-12" src="https://chance-1300454446.cos.ap-guangzhou.myqcloud.com/logo.png" alt="chance" />
        </div>
        <Form name="basic" labelCol={{ span: 5 }} wrapperCol={{ span: 16 }} onFinish={onFinish}>
          <Form.Item
            label="用户名"
            name="username"
            rules={[
              {
                required: true,
                message: "必填!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="密码"
            name="password"
            rules={[
              {
                required: true,
                message: "必填!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 5, span: 16 }}>
            <Button loading={submitting} type="primary" block htmlType="submit">
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
