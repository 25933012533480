import React from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import "./App.css"
import DefaultLayout from "./components/layout/Default"
import { PriovideAuth } from "./utils/auth"

function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <PriovideAuth>
        <DefaultLayout></DefaultLayout>
      </PriovideAuth>
    </DndProvider>
  )
}

export default App
