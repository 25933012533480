import request from "../utils/request"

export function getOrganizationList({page = 1, size = 20, city = "", search = ""} = {}) {
    return request.get(`/organizations?page=${page}&size=${size}&city=${city}&search=${search}`)
}

export function createOrganization(body) {
    return request.post(`/organizations`, body)
}

export function getOrganizationById(id) {
    return request.get(`/organizations/${id}`)
}


export function updateOrganizationById(id, body) {
    return request.put(`/organizations/${id}`, body)
}

export function deleteOrganizationById(id) {
    return request.delete(`/organizations/${id}`)
}
