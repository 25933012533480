import { LeftOutlined, PlusOutlined } from "@ant-design/icons"
import React, { useCallback, useEffect, useState } from "react"
import {
  Button,
  Cascader,
  DatePicker,
  Modal,
  Form,
  Input,
  InputNumber,
  message,
  Popover,
  Select,
  Space,
  Spin,
  Divider,
} from "antd"

export default function AddableSelect({ onCreate, createPlaceholder, children, ...props }) {
  const [newOrgName, setNewOrgName] = useState()
  const [loading, setLoading] = useState(false)
  const onNewOrgNameChange = (e) => setNewOrgName(e.target.value)
  const handleCreate = async () => {
    setLoading(true)
    try {
      await onCreate(newOrgName)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Select
      {...props}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: "4px 0" }} />
          <div className="p-2 w-full flex">
            <Input
              className="mr-1"
              allowClear
              value={newOrgName}
              onChange={onNewOrgNameChange}
              placeholder={createPlaceholder}
            />
            <Button onClick={handleCreate} loading={loading} icon={<PlusOutlined />}>
              添加新的机构
            </Button>
          </div>
        </div>
      )}
    >
      {children}
    </Select>
  )
}
