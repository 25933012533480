import { Layout } from "antd"
import { HashRouter as Router, Route, Switch } from "react-router-dom"
import { Menus, Routers, routes } from "./../../router"
import Login from "./../../views/Login"
import ProtectedRoute from "./../ProtectedRoute"
import "./Default.css"

const { Header, Sider, Content } = Layout

export default function DefaultLayout() {
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Login></Login>
        </Route>
        <ProtectedRoute path="/">
          <Layout className="h-screen flex-row">
            <Sider trigger={null} collapsible>
              <div className="logo px-4 py-4 flex justify-center">
                <img
                  className="h-8"
                  src="https://chance-1300454446.cos.ap-guangzhou.myqcloud.com/logo.png"
                  alt="chance"
                />
              </div>
              <Menus routes={routes}></Menus>
            </Sider>
            <Layout className="site-layout">
              <Header className="site-layout-background" style={{ padding: 0 }}></Header>
              <Content
                className="site-layout-background p-4 overflow-y-scroll overflow-x-hidden"
                style={{ minHeight: 280 }}
              >
                <Routers routes={routes}></Routers>
              </Content>
            </Layout>
          </Layout>
        </ProtectedRoute>
      </Switch>
    </Router>
  )
}
