import {LeftOutlined, PlusOutlined} from "@ant-design/icons"
import {
  Button,
  Cascader,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popover,
  Select,
  Space,
  Spin,
} from "antd"
import ImgCropper from "./../../components/ImgCropper"
import update from "immutability-helper"
import moment from "moment"
import React, {useCallback, useEffect, useState} from "react"
import {Link, useParams,useHistory} from "react-router-dom"
import {hotCity,cityMap} from "../../assets/js/hotCity"
import {upload} from "./../../api/cos"
import {createEvent, getEventById, updateEventById} from "./../../api/event"
import {createOrganization, getOrganizationList, updateOrganizationById} from "./../../api/organization"
import {getSpiderContent, getSpiderMapping} from "./../../api/spiderMapping"
import AsyncContent from "./../../components/container/AsyncContent"
import AddableSelect from "../../components/AddableSelect"
import COSUpload, {genKey} from "./../../components/COSUpload"
import Editor from "./../../components/Editor"
import {putObject} from "./../../utils/cos"
import "./Event.css"

let uid = 0
function formatURLs(urls) {
  if (urls && typeof urls === "string") {
    return [
      {
        url: urls,
        status: "done",
        uid: uid++,
        name: "cover",
      },
    ]
  }

  if (Array.isArray(urls)) {
    return urls.map((url) => ({
      url,
      status: "done",
      uid: uid++,
      name: "cover",
    }))
  }

  return []
}

// resToForm 将响应的数据转换为Form组件识别的数据结构
function resToForm(res) {
  const { city, district, startAt, endAt, createdAt } = res
  const pca = [city, district].filter(Boolean)

  const dateRange = [startAt, endAt].filter(Boolean).map((v) => moment(v))
  const cover = formatURLs(res.cover)
  const qrcode = formatURLs(res.qrcode)
  const banner = formatURLs(res.banner)

  return { ...res, createdAt: moment(createdAt), dateRange, pca, cover, qrcode, banner }
}

// formToReq 将Form组件的数据结构转为请求接口需要的数据结构
function formToReq(form) {
  const { banner = [], qrcode = [], cover = [], createdAt, pca = [], dateRange = [] } = form
  const bannerURLs = banner.map((b) => b.url)
  const coverURL = (cover && cover.length && cover[0].url) || ""
  const qrcodeURL = (qrcode && qrcode.length && qrcode[0].url) || ""
  const [city = "", district = ""] = pca
  const [startAt, endAt] = dateRange
  const province = cityMap[city]
  const data = {
    ...form,
    province,
    city,
    district,
    banner: bannerURLs,
    cover: coverURL,
    qrcode: qrcodeURL,
    createdAt: createdAt && createdAt.format("YYYY-MM-DD HH:mm:ss"),
    startAt: startAt ? startAt.format("YYYY-MM-DD 00:00:00") : "",
    endAt: endAt ? endAt.format("YYYY-MM-DD 23:59:59") : "",
  }

  Reflect.deleteProperty(data, "dateRange")
  Reflect.deleteProperty(data, "pca")
  return data
}

const stringRule = [{ type: "string", required: true, message: "必填!" }]
const timeRule = [{ type: "object", required: true, message: "必填!" }]
const numberRule = [{ type: "number", required: true, message: "必填!" }]
const arrayRule = [{ type: "array", required: true, message: "必填!" }]

function notMoreFiles(getFieldValue, field, count) {
  const value = getFieldValue(field)
  if (value && value.length) {
    return value.length >= count
  }

  return false
}

const localUpload = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>本地上传</div>
  </div>
)

function UrlUpload({ onChange, fileList, eventID }) {
  const [url, setUrl] = useState("")
  const [modalVisible, setModalVisible] = useState(false)
  const [inputVisible, setInputVisible] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [cropper, setCropper] = useState()

  const onInitialized = (cropper) => {
    setCropper(cropper)
  }

  const uploadImage = useCallback(
    (noCrop) => {
      if (noCrop) {
        const newFileList = update(fileList, { $push: formatURLs(`${url}`) })
        onChange({ fileList: newFileList })
        setModalVisible(false)
        return
      }

      cropper.getCroppedCanvas().toBlob(
        async function (blob) {
          setUploading(true)
          try {
            const res = await putObject(genKey(eventID, `${Math.random().toString().substring(2)}.jpeg`), blob)

            if (onChange) {
              const newFileList = update(fileList, { $push: formatURLs(`https://${res.Location}`) })
              onChange({ fileList: newFileList })
            }
            setModalVisible(false)
          } catch (e) {
            console.error(e)
          } finally {
            setUploading(false)
          }
        },
        "image/jpeg",
        1
      )
    },
    [cropper, fileList, url, onChange, eventID]
  )

  const uploadURL = async () => {
    setUploading(true)
    try {
      const res = await upload(url, eventID + "")
      setUrl(res.url)
      setModalVisible(true)
    } catch (error) {
      console.error(error)
    } finally {
      setUrl("")
      setInputVisible(false)
      setUploading(false)
    }
  }

  const handleCancel = () => setModalVisible(false)
  const handleVisibleChange = visible => {
    setInputVisible(visible)
  };
  return (
    <Popover
      content={
        <div className="flex flex-row text-white divide-x">
          <Space>
            <Input
              className="w-80"
              value={url}
              placeholder="请输入图片链接并上传"
              onChange={(e) => setUrl(e.target.value)}
            />
            <Button loading={uploading} type="primary" onClick={uploadURL}>
              上传
            </Button>
          </Space>
        </div>
      }
      visible={inputVisible}
      onVisibleChange={handleVisibleChange}
      title={<div className="text-white">上传网络图片</div>}
      trigger="click"
      placement="bottom"
      color="#333"
    >
      <Button type="dashed" icon={<PlusOutlined />}>
        网络图片上传
      </Button>
      <Modal
        title="图片裁剪"
        okText="确定"
        cancelText="取消"
        confirmLoading={uploading}
        visible={modalVisible}
        onOk={uploadImage}
        onCancel={handleCancel}
        maskClosable={false}
        zIndex={2000}
        footer={
          <div>
            <Button onClick={handleCancel}>取消</Button>
            <Button onClick={() => uploadImage(true)}>不裁剪上传</Button>
            <Button type="primary" onClick={() => uploadImage(false)}>
              确定裁剪上传
            </Button>
          </div>
        }
      >
        <ImgCropper src={url} onInitialized={onInitialized} />
      </Modal>
    </Popover>
  )
}

function TwoWayUpload({ maxFiles, children, eventID, fileList, onChange, ...props }) {
  return (
    <div>
      <COSUpload
        {...props}
        onChange={onChange}
        fileList={fileList}
        name="file"
        listType="picture-card"
        eventID={eventID}
        action="https://chance-1300454446.cos.ap-guangzhou.myqcloud.com/"
      >
        {maxFiles ? null : localUpload}
      </COSUpload>
      {!maxFiles && <UrlUpload onChange={onChange} eventID={eventID} fileList={fileList}></UrlUpload>}
    </div>
  )
}

export default function EventDetail() {
  const history = useHistory()
  let { id } = useParams()
  const [form] = Form.useForm()
  const [orgs, setOrgs] = useState([])
  const [sourceURL, setSourceURL] = useState("")
  const [fetching, setFetching] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [published, setPublished] = useState(false)

  let publish = false
  id = id ? Number(id) : 0

  const fetchData = useCallback(async () => {
    if (id>0) {
      const res = await getEventById(id)
      form.setFieldsValue(resToForm(res))
      setPublished(res.isRecommended)

      return res
    }
    return {}
  }, [form, id])

  const updateContentUseSpider = useCallback(async () => {
    setFetching(true)
    try {
      const html = await getSpiderContent(id, 2, sourceURL)
      form.setFieldsValue({ content: html })
    } catch (error) {
      console.error(error)
    } finally {
      setFetching(false)
    }
  }, [form, id, sourceURL])

  const fetchOrgs = async () => {
    const ores = await getOrganizationList()
    setOrgs(ores.list)
  }

  useEffect(() => {
    async function fetchSpider() {
      const mapping = await getSpiderMapping(id, 2)
      setSourceURL(mapping.url)
    }
    fetchOrgs()
    fetchSpider()
  }, [id])

  const triggerSubmit = async (isPublish) => {
    try {
      await form.validateFields()
    } catch (error) {
      console.error(error)
      return message.error("请检查表单错误")
    }

    publish = isPublish
    form.submit()
  }

  const submit = async (values) => {
    const data = formToReq(values)
    data.isRecommended = publish

    const { organizationId } = data
    const selectedOrg = orgs.find((org) => org.id === organizationId)
    if (selectedOrg && (!selectedOrg.address || !selectedOrg.province)) {
      Modal.confirm({
        content: <div>检测到该机构未绑定地址，是否将当前地址绑定到该机构？</div>,
        okText: "绑定并提交",
        cancelText: "不绑定并提交",
        onOk: () => callAPI(true),
        onCancel: () => callAPI(false),
      })
    } else {
      callAPI(false)
    }

    async function callAPI(bindAddr) {
      setSubmitting(true)

      try {
        if (bindAddr) {
          const { province, city, district, address } = data
          const orgData = {
            province,
            city,
            district,
            address,
          }
          await updateOrganizationById(organizationId, orgData)
        }
        if (id >0){
          await updateEventById(id, data)
        } else {
          const result = await createEvent(data)
          history.push(`/events/list/${result}`)
          message.success("保存成功")
          return
        }

        fetchOrgs()
        message.success("操作成功")
        setPublished(publish)
      } catch (error) {
        console.error(error)
      } finally {
        setSubmitting(false)
      }
    }
  }

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }

    return e && e.fileList
  }

  const orgChange = (e) => {
    const o = orgs.find((org) => org.id === e)
    form.setFieldsValue({ pca: [o.city, o.district], address: o.address })
  }

  const addNewOrg = async (orgName) => {
    const orgID = await createOrganization({ name: orgName })
    const newOrgs = orgs.concat([{ id: orgID, name: orgName }])
    setOrgs(newOrgs)
    form.setFieldsValue({ organizationId: orgID })
  }

  return (
    <AsyncContent fetch={fetchData} defaultData={{}}>
      {({ data = {}, loading }) => (
        <Spin spinning={loading}>
          <div className="detail__topbar sticky flex justify-between bg-white py-2 px-4 border-b z-10">
            <Space>
              <Button icon={<LeftOutlined />}>
                <Link to="/events/list">返回列表</Link>
              </Button>
              <Button loading={submitting} type="primary" onClick={() => triggerSubmit(true /* 发布 */)}>
                {published ? "更新" : "马上发布"}
              </Button>
              {!published && (
                <Button loading={submitting} onClick={() => triggerSubmit(false /* 草稿 */)}>
                  保存草稿
                </Button>
              )}
            </Space>
            <Space>
              <Input
                value={sourceURL}
                onChange={(e) => setSourceURL(e.target.value)}
                className="w-60"
                placeholder="请输入详情URL"
              ></Input>
              <Button loading={fetching} onClick={updateContentUseSpider}>
                拉取公众号文章
              </Button>
            </Space>
          </div>
          <div className="p-4">
            <Form {...layout} layout="horizontal" form={form} onFinish={submit} validateTrigger="onBlur">
              <Form.Item label="标题" name="name" rules={stringRule}>
                <Input className="w-96" placeholder="请输入展览标题" />
              </Form.Item>
              <Form.Item label="副标题" name="subname" rules={stringRule}>
                <Input className="w-96" placeholder="请输入展览副标题" />
              </Form.Item>
              <Form.Item label="简介" name="brief" rules={stringRule}>
                <Input.TextArea className="w-96" rows={4} placeholder="请输入展览简介" />
              </Form.Item>
              <Form.Item label="排序时间" name="createdAt" rules={timeRule}>
                <DatePicker showTime={{ format: "HH" }} placeholder="请选择用于排序的日期"></DatePicker>
              </Form.Item>
              <Form.Item label="属于机构" name="organizationId" rules={numberRule}>
                <AddableSelect
                  showSearch
                  style={{ width: "24rem" }}
                  placeholder="请选择举办展览的机构"
                  onChange={orgChange}
                  onCreate={addNewOrg}
                  createPlaceholder="输入新的机构名称"
                >
                  {orgs.map((org) => (
                    <Select.Option key={org.id} value={org.id}>
                      {org.name}
                    </Select.Option>
                  ))}
                </AddableSelect>
              </Form.Item>
              <Form.Item label="活动时间" name="dateRange" rules={arrayRule}>
                <DatePicker.RangePicker format="YYYY-MM-DD"></DatePicker.RangePicker>
              </Form.Item>
              <Form.Item label="门票费用" name="cost">
                <InputNumber className="w-96" placeholder="请输入展览的门票费" min={0} />
              </Form.Item>
              <Form.Item label="活动地点" name="pca" rules={arrayRule}>
                <Cascader
                  options={hotCity}
                  fieldNames={{ value: "label" }}
                  placeholder="请选择城市"
                  style={{ width: "24rem" }}
                />
              </Form.Item>
              <Form.Item label="详细地址" name="address" rules={stringRule}>
                <Input className="w-96 mt-2" placeholder="请输入详细地址"></Input>
              </Form.Item>
              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.cover !== currentValues.cover}>
                {({ getFieldValue }) => (
                  <Form.Item label="封面图" name="cover" valuePropName="fileList" getValueFromEvent={normFile}>
                    <TwoWayUpload eventID={data.id} maxFiles={notMoreFiles(getFieldValue, "cover", 1)}></TwoWayUpload>
                  </Form.Item>
                )}
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.banner !== currentValues.banner}
              >
                {({ getFieldValue }) => (
                  <Form.Item label="轮播图" name="banner" valuePropName="fileList" getValueFromEvent={normFile}>
                    <TwoWayUpload eventID={data.id} maxFiles={notMoreFiles(getFieldValue, "banner", 9)}></TwoWayUpload>
                  </Form.Item>
                )}
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.qrcode !== currentValues.qrcode}
              >
                {({ getFieldValue }) => (
                  <Form.Item label="线上二维码" name="qrcode" valuePropName="fileList" getValueFromEvent={normFile}>
                    <TwoWayUpload eventID={data.id} maxFiles={notMoreFiles(getFieldValue, "qrcode", 1)}></TwoWayUpload>
                  </Form.Item>
                )}
              </Form.Item>
              <Form.Item
                label="详情"
                name="content"
                shouldUpdate={(prevValues, currentValues) => prevValues.content !== currentValues.content}
              >
                <Editor className="w-96"></Editor>
              </Form.Item>
            </Form>
          </div>
        </Spin>
      )}
    </AsyncContent>
  )
}
