import {Button, Cascader, Input, message, Popconfirm, Popover, Space, Table} from "antd"
import React, {useCallback, useMemo, useState} from "react"
import {createOrganization, deleteOrganizationById, getOrganizationList} from "./../../api/organization"
import {Link} from "react-router-dom"
import AsyncContent from "./../../components/container/AsyncContent"
import {areaCity} from "../../assets/js/areaCity"


export default function OrgList() {
    const columns = [
        {
            title: "名称",
            dataIndex: "name",
            key: "name",
            width: 220,
            render: (text, row) => <Link target="_blank" to={`/org/list/${row.id}`}>{text}</Link>,
        },
        {
            title: "城市",
            dataIndex: "city",
            key: "city",
            width: 220,
        },
        {
            title: "操作",
            key: "action",
            fixed: "right",
            render: (_, row) => (
                <Space size="middle">
                    <Popconfirm title="确认删除?" okText="删除" cancelText="达咩🙅" onConfirm={() => deleteOrg(row)}>
                        <Button type="link" danger size="small">
                            删除
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        }
    ]
    const [current, setCurrent] = useState(1)
    const [size, setSize] = useState(20)
    const [newOrg, setNewOrg] = useState("")
    const [reloadFlag, setReloadFlag] = useState(0)
    const [filter, setFilter] = useState({})

    const pagination = useMemo(
        () => ({
            current: current,
            pageSize: size,
        }),
        [current, size]
    )

    const reloadList = () => {
        setReloadFlag(reloadFlag + 1)
    }

    const fetchData = useCallback(async () => {
        const {search, city = []} = filter
        const res = await getOrganizationList({
            page: pagination.current,
            size: pagination.pageSize,
            search: search,
            city: city[1] || city[0]
        })
        return res
    }, [pagination, filter])

    const handleCityChange = (value) => {
        setFilter({
            ...filter,
            city: value,
        })
    }

    const onSearch = (value) => {
        setFilter({
            ...filter,
            search: value,
        })
    }

    const onTableChange = ({current, pageSize}, filters) => {
        setSize(pageSize)
        setCurrent(current)
        setFilter({
            ...filter,
            ...filters,
        })
    }

    const createOrg = async () => {
        const data = await createOrganization({name: newOrg})
        window.open(`./#/org/list/${data}`, "_blank")
        reloadList()
        setNewOrg("")
    }

    const deleteOrg = async (row) => {
        try {
            await deleteOrganizationById(row.id)
            reloadList()
            message.success("操作成功")
        } catch (error) {
            console.error(error)
        }
    }

    const Filters = (
        <div>
            <Cascader
                options={areaCity}
                fieldNames={{value: "label"}}
                placeholder="请选择城市"
                style={{width: 150}}
                onChange={handleCityChange}
                showSearch
                value={filter.city}
            />
        </div>
    )

    return (
        <AsyncContent fetch={fetchData} reloadFlag={reloadFlag}>
            {({data, loading}) => (
                <div className="p-4">
                    <div className="mb-2 flex justify-between">
                        <Space>
                            <Link target="_blank" to={`/org/add`} style={{color:'#1890ff',textDecoration:'underline'}}>新增机构</Link>
                        </Space>
                        <Space>
                            {Filters}
                            <Input.Search placeholder="名称搜索" className="w-60" onSearch={onSearch}/>
                        </Space>
                    </div>
                    <Table
                        className="w-full"
                        loading={loading}
                        columns={columns}
                        dataSource={data?.list}
                        pagination={{
                            ...pagination,
                            total: data?.count,
                            showTotal: (t) => `共${t}条`,
                            size: "default",
                            position: ["bottomCenter"],
                        }}
                        size="small"
                        rowKey="id"
                        bordered
                        onChange={onTableChange}
                    />
                </div>
            )}
        </AsyncContent>
    )
}
