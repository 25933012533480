import { Tooltip, Upload, Modal, Button } from "antd"
import ImgCrop from "antd-img-crop"
import dayjs from "dayjs"
import update from "immutability-helper"
import React, { useCallback, useState } from "react"
import { useDrag, useDrop } from "react-dnd"
import ImgCropper from "./ImgCropper"
import { getAuth, putObject } from "./../utils/cos"
import "./COSUpload.css"

export function genKey(eventID, fileName) {
  return `event-image/Image of Activity/${dayjs().format("YYYY-MM-DD")} ${eventID}/${new Date().getTime()}${fileName}`
}

const type = "DragableUploadList"

const DragableUploadListItem = ({ originNode, moveRow, file, fileList }) => {
  const ref = React.useRef()
  const index = fileList.indexOf(file)
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {}
      if (dragIndex === index) {
        return {}
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      }
    },
    drop: (item) => {
      moveRow(item.index, index)
    },
  })
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  drop(drag(ref))
  const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item h-full ${isOver ? dropClassName : ""}`}
      style={{ cursor: "move" }}
    >
      {file.status === "error" ? errorNode : originNode}
    </div>
  )
}

export default function COSUpload({ eventID, onChange, fileList, ...props }) {
  const [file, setFile] = useState()
  const [modalVisible, setModalVisible] = useState(false)
  const [uploadPromise, setUploadPromise] = useState({})

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = fileList[dragIndex]
      const newList = update(fileList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      })
      onChange({ fileList: newList })
    },
    [onChange, fileList]
  )

  const privateProps = {
    // transformFile: (file) => {
    //   console.log(file)
    //   return new Promise(() => {})
    // },
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader()
        fr.readAsDataURL(file)
        fr.onload = function (e) {
          setFile(e.target.result)
          setModalVisible(true)
        }

        const _resolve = (blob) => {
          if (blob) {
            resolve(blob)
          } else {
            resolve(file)
          }
        }

        setUploadPromise({ resolve: _resolve, reject })
      })
    },
    onChange: ({ file, fileList, ...rest }) => {
      if (file.xhr) {
        const url = file.xhr.getResponseHeader("Location")

        file.url = encodeURI(url.replace("http", "https"))
      }

      if (onChange) {
        onChange({ file, fileList, ...rest })
      }

      return { file, fileList, ...rest }
    },
    data: (file) => {
      return {
        signature: getAuth(),
        key: genKey(eventID, file.name),
      }
    },
  }

  const [cropper, setCropper] = useState()

  const onInitialized = (cropper) => {
    setCropper(cropper)
  }

  const handleOK = (noCrop) => {
    if (noCrop) {
      uploadPromise.resolve()
      setModalVisible(false)
      return
    }

    cropper.getCroppedCanvas().toBlob(
      async function (blob) {
        uploadPromise.resolve(blob)
        setModalVisible(false)
      },
      "image/jpeg",
      1
    )
  }
  const handleCancel = () => {
    setModalVisible(false)
  }

  return (
    <div>
      <Upload
        {...props}
        fileList={fileList}
        itemRender={(originNode, file, currFileList) => (
          <DragableUploadListItem originNode={originNode} file={file} fileList={currFileList} moveRow={moveRow} />
        )}
        {...privateProps}
      >
        {props.children}
      </Upload>
      <Modal
        title="图片裁剪"
        okText="确定"
        cancelText="取消"
        visible={modalVisible}
        onOk={handleOK}
        onCancel={handleCancel}
        maskClosable={false}
        zIndex={2000}
        footer={
          <div>
            <Button onClick={handleCancel}>取消</Button>
            <Button onClick={() => handleOK(true)}>不裁剪上传</Button>
            <Button type="primary" onClick={() => handleOK(false)}>
              确定裁剪上传
            </Button>
          </div>
        }
      >
        <ImgCropper src={file} onInitialized={onInitialized} />
      </Modal>
    </div>
  )
}
