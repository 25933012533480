import { BankFilled, ReadFilled, RocketFilled } from "@ant-design/icons"
import { Menu } from "antd"
import React from "react"
import { Link, Route, Switch, useLocation } from "react-router-dom"
import { joinPath } from "./../utils"
import EventList from "./../views/event/EventList"
import EventDetail from "../views/event/EventDetail"
import OrgList from "../views/org/OrgList"
import OrgDetail from "../views/org/OrgDetail"
import BoatList from "../views/boat/BoatList"

export const routes = [
  {
    path: "/events",
    icon: ReadFilled,
    name: "展览",
    children: [
      {
        path: "list",
        icon: null,
        name: "展览",
        exact: true,
        menu: true,
        component: EventList,
      },
      {
        path: "list/:id",
        icon: null,
        name: "展览详情",
        exact: false,
        component: EventDetail,
      },
      {
        path: "add",
        icon: null,
        name: "新增展览",
        exact: false,
        component: EventDetail,
      },
    ],
  },
  {
    path: "/org",
    icon: BankFilled,
    name: "机构",
    children: [
      {
        path: "list",
        icon: null,
        name: "机构",
        exact: true,
        menu: true,
        component: OrgList,
      },
      {
        path: "list/:id",
        icon: null,
        name: "机构详情",
        exact: false,
        component: OrgDetail,
      },
      {
        path: "add",
        icon: null,
        name: "新增机构",
        exact: false,
        component: OrgDetail,
      }
    ],
  },
  {
    path: "/boat",
    icon: RocketFilled,
    name: "船",
    children: [
      {
        path: "list",
        icon: null,
        name: "船列表",
        exact: true,
        menu: true,
        component: BoatList,
      }
    ]
  }
]

const flattenRoutes = []
walkRoutes(routes, (route, parents) => {
  const paths = parents.map((p) => p.path)

  flattenRoutes.push({
    ...route,
    path: joinPath(...paths, route.path),
    children: undefined,
  })
})

console.log(flattenRoutes)

export function Menus({ routes, parentPath = "" }) {
  const location = useLocation()
  const selectedKeys = flattenRoutes
    .filter((route) => location.pathname.startsWith(route.path))
    .map((route) => route.path)

  return (
    <Menu theme="dark" mode="inline" selectedKeys={selectedKeys}>
      {routes.map((route) => {
        const Icon = route.icon
        const IconElement = route.icon ? <Icon /> : undefined
        const menuChildren = route?.children?.filter((child) => child.menu) || []

        const hasOverTwoChildren = route.children && menuChildren.length > 1
        let path = joinPath(parentPath, route.path)
        let menuName = route.name
        let isMenu = false

        if (menuChildren.length === 1) {
          const child = menuChildren[0]
          path = joinPath(path, child.path)
          menuName = child.name
          isMenu = child.menu
        }

        if (hasOverTwoChildren) {
          return (
            <Menu.SubMenu key={path} icon={IconElement} title={route.name}>
              <Menus routes={route.children} parentPath={path} />
            </Menu.SubMenu>
          )
        }

        if (isMenu) {
          return (
            <Menu.Item key={path} icon={IconElement}>
              <Link to={path}>{menuName}</Link>
            </Menu.Item>
          )
        }

        return false
      })}
    </Menu>
  )
}

export function Routers() {
  return (
    <Switch>
      {flattenRoutes.map((route) => {
        const RouteComponent = route.component
        return (
          <Route path={route.path} exact={route.exact} key="route.path">
            <RouteComponent></RouteComponent>
          </Route>
        )
      })}
    </Switch>
  )
}

function walkRoutes(routes, cb, parents = []) {
  routes.forEach((route) => {
    if (route.children && route.children.length) {
      return walkRoutes(route.children, cb, parents.concat(route))
    }

    cb(route, parents)
  })
}
