import React, { useEffect, useState } from "react"

export default function AsyncContent({ children, defaultData, fetch, reloadFlag }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [data, setData] = useState(defaultData)

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      try {
        const res = await fetch()
        setData(res)
      } catch (error) {
        console.error(error)
        setError(error.toString())
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [fetch, reloadFlag])

  let renderContent = null
  if (error) {
    renderContent = <div className="p-4 text-red-500 flex justify-center">{error}</div>
  } else {
    renderContent = children({ data, loading })
  }

  return <div className="min-h-full bg-white">{renderContent}</div>
}
