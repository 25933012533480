import React, { useState, useCallback } from "react"
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./ImgCropper.css"

const ImgCropper = ({ src, onInitialized }) => {
  return (
    <div className="App">
      <div className="crop-container">
        <Cropper
            style={{ height: 400, width: "100%" }}
            zoomTo={0.5}
            initialAspectRatio={1}
            src={src}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            onInitialized={onInitialized}
            guides={true}
            dragMode="move"
          />
      </div>
    </div>
  )
}

export default ImgCropper
