import React, { useCallback, useMemo, useState } from "react"
import { Input, Space, Table, Cascader, Popconfirm, message, Button } from "antd"
import dayjs from "dayjs"
import { getBoatList, deleteBoatById } from "./../../api/boat"
import AsyncContent from "./../../components/container/AsyncContent"
import { areaCity } from "../../assets/js/areaCity"

export default function BoatList() {
  const [current, setCurrent] = useState(1)
  const [size, setSize] = useState(20)
  const [filter, setFilter] = useState({})
  const [reloadFlag, setReloadFlag] = useState(0)

  const pagination = useMemo(
    () => ({
      current: current,
      pageSize: size,
    }),
    [current, size]
  )

    const fetchData = useCallback(async () => {
    const { search, city=[] } = filter
    const res = await getBoatList({
      page: pagination.current,
      size: pagination.pageSize,
      search:search,
      city: city[1] || city[0]
    })
    return res
  }, [pagination,filter])

     const onSearch = (value)=>{
       setFilter({
      ...filter,
      search: value,
    })
  }

    const onTableChange = ({ current, pageSize }, filters) => {
    setSize(pageSize)
    setCurrent(current)
    setFilter({
      ...filter,
      ...filters,
    })
  }

  const handleCityChange = (value) => {
    setFilter({
      ...filter,
      city: value,
    })
  }

  const deleteBoat = async (row) => {
    try {
      await deleteBoatById(row.id)
      reloadList()
      message.success("操作成功")
    } catch (error) {
      console.error(error)
    }
  }

  const reloadList = () => {
    setReloadFlag(reloadFlag + 1)
  }

  const Filters = (
    <div>
          <Cascader
           options={areaCity}
           fieldNames={{ value: "label" }}
           placeholder="请选择城市"
           style={{ width: 150 }}
           onChange={handleCityChange}
           showSearch
           value={filter.city}
         />
    </div>
  )


  const columns = [
  {
    title: "标题",
    dataIndex: "title",
    key: "title",
    width: 220,
    // render: (text, row) => <Link target="_blank" to={`/org/list/${row.id}`}>{text}</Link>,
  },
  {
    title: "创建时间",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 220,
    render: (value) => {
        return dayjs(value).format("YYYY年MM月DD日 HH时mm分")
    },
  },
   {
    title: "启航时间",
    dataIndex: "startAt",
    key: "startAt",
    width: 220,
    render: (value) => {
        return dayjs(value).format("YYYY年MM月DD日 HH时mm分")
    },
  },
   {
    title: "类型",
    dataIndex: "type",
    key: "type",
    width: 220,
    render: (value) => {
      return value === 1 ? "线下":"线上"
    }
  },
  {
    title: "船长微信号",
    dataIndex: "wechat",
    key: "wechat",
    width: 220,
    render: (value) => value  ? (value) : ("-")
  },
  {
      title: "操作",
      key: "action",
      fixed: "right",
      render: (_, row) => (
        <Space size="middle">
          <Popconfirm title="确认删除?" okText="删除" cancelText="达咩🙅" onConfirm={() => deleteBoat(row)}>
            <Button type="link" danger size="small">
              删除
            </Button>
          </Popconfirm>
        </Space>
      ),
    }
]

  return (
	<AsyncContent fetch={fetchData} reloadFlag={reloadFlag}>
      {({ data, loading }) => (
        <div className="p-4">
          <div className="mb-2 flex" style={{"justify-content": "right"}}>
              {Filters}
              <Input.Search placeholder="标题搜索" className="w-60" onSearch={onSearch}/>
          </div>
          <Table
            className="w-full"
            loading={loading}
            columns={columns}
            dataSource={data?.list}
            pagination={{
              ...pagination,
              total: data?.count,
              showTotal: (t) => `共${t}条`,
              size: "default",
              position: ["bottomCenter"],
            }}
            size="small"
            rowKey="id"
            bordered
            onChange={onTableChange}
          />
        </div>
      )}
    </AsyncContent>
  )
}