import { message } from "antd"
import axios from "axios"
import { getLocalToken } from "./auth"
import { useAuth } from "./../utils/auth"

const service = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
  timeout: 60000,
})

service.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    let token = getLocalToken()

    if (token) {
      config.headers.Authorization = token //将token放到请求头发送给服务器
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    if (response.data.success) {
      return response.data.data
    }

    console.log(response)

    message.error(response?.data?.msg?.toString())
    return Promise.reject(response.data.msg)
  },
  (error) => {
    if (error && error.response) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-restricted-globals
        location.hash = "#/login"
        return
      }

      message.error(error.response?.data?.toString())
      return Promise.reject(error.response?.data?.toString())
    }

    message.error(error.toString())
    return Promise.reject(error)
  }
)
export default service
