var COS = require("cos-js-sdk-v5")
var cos = new COS({
  SecretId: "AKIDXoqeBRDmsjFV4Q6bhaEBOlsinyd9jAaS",
  SecretKey: "cs77tTpOo1KRurYzJWjtyMafbhhdQHwU",
})

export function getAuth() {
  return window.CosAuth({
    Method: "POST",
    Pathname: "/",
    SecretId: "AKIDXoqeBRDmsjFV4Q6bhaEBOlsinyd9jAaS",
    SecretKey: "cs77tTpOo1KRurYzJWjtyMafbhhdQHwU",
  })
}

export function getObjectUrl(key) {
  return cos.getObjectUrl({
    Bucket: "chance-1300454446",
    Region: "ap-guangzhou" /* 存储桶所在地域，必须字段 */,
    Key: key,
    Sign: false /* 获取不带签名的对象URL */,
  })
}

export function putObject(key, file) {
  return new Promise((resolve, reject) => {
    cos.putObject(
      {
        Bucket: "chance-1300454446" /* 必须 */,
        Region: "ap-guangzhou" /* 存储桶所在地域，必须字段 */,
        Key: key /* 必须 */,
        Body: file /* 必须 */,
      },
      function (err, data) {
        if (err) {
          reject(err.message)
        }
        resolve(data)
      }
    )
  })
}
