export const hotCity = [
    {
        id: "1022",
        label: "北京市",
        value: "110100",
        children: [
            {
                id: "44",
                label: "东城区",
                value: "110101",
                children: [],
            },
            {
                id: "45",
                label: "西城区",
                value: "110102",
                children: [],
            },
            {
                id: "46",
                label: "朝阳区",
                value: "110105",
                children: [],
            },
            {
                id: "47",
                label: "丰台区",
                value: "110106",
                children: [],
            },
            {
                id: "48",
                label: "石景山区",
                value: "110107",
                children: [],
            },
            {
                id: "49",
                label: "海淀区",
                value: "110108",
                children: [],
            },
            {
                id: "50",
                label: "门头沟区",
                value: "110109",
                children: [],
            },
            {
                id: "51",
                label: "房山区",
                value: "110111",
                children: [],
            },
            {
                id: "52",
                label: "通州区",
                value: "110112",
                children: [],
            },
            {
                id: "53",
                label: "顺义区",
                value: "110113",
                children: [],
            },
            {
                id: "54",
                label: "昌平区",
                value: "110114",
                children: [],
            },
            {
                id: "55",
                label: "大兴区",
                value: "110115",
                children: [],
            },
            {
                id: "56",
                label: "怀柔区",
                value: "110116",
                children: [],
            },
            {
                id: "57",
                label: "平谷区",
                value: "110117",
                children: [],
            },
            {
                id: "58",
                label: "密云县",
                value: "110228",
                children: [],
            },
            {
                id: "59",
                label: "延庆县",
                value: "110229",
                children: [],
            }
        ],
    },
    {
        id: "1096",
        label: "上海市",
        value: "310100",
        children: [
            {
                id: "825",
                label: "黄浦区",
                value: "310101",
                children: [],
            },
            {
                id: "826",
                label: "卢湾区",
                value: "310103",
                children: [],
            },
            {
                id: "827",
                label: "徐汇区",
                value: "310104",
                children: [],
            },
            {
                id: "828",
                label: "长宁区",
                value: "310105",
                children: [],
            },
            {
                id: "829",
                label: "静安区",
                value: "310106",
                children: [],
            },
            {
                id: "830",
                label: "普陀区",
                value: "310107",
                children: [],
            },
            {
                id: "831",
                label: "闸北区",
                value: "310108",
                children: [],
            },
            {
                id: "832",
                label: "虹口区",
                value: "310109",
                children: [],
            },
            {
                id: "833",
                label: "杨浦区",
                value: "310110",
                children: [],
            },
            {
                id: "834",
                label: "闵行区",
                value: "310112",
                children: [],
            },
            {
                id: "835",
                label: "宝山区",
                value: "310113",
                children: [],
            },
            {
                id: "836",
                label: "嘉定区",
                value: "310114",
                children: [],
            },
            {
                id: "837",
                label: "浦东新区",
                value: "310115",
                children: [],
            },
            {
                id: "838",
                label: "金山区",
                value: "310116",
                children: [],
            },
            {
                id: "839",
                label: "松江区",
                value: "310117",
                children: [],
            },
            {
                id: "840",
                label: "青浦区",
                value: "310118",
                children: [],
            },
            {
                id: "841",
                label: "奉贤区",
                value: "310120",
                children: [],
            },
            {
                id: "842",
                label: "崇明县",
                value: "310230",
                children: [],
            }
        ],
    },
    {
        id: "1222",
        label: "广州市",
        value: "440100",
        children: [
            {
                id: "1973",
                label: "荔湾区",
                value: "440103",
                children: [],
            },
            {
                id: "1974",
                label: "越秀区",
                value: "440104",
                children: [],
            },
            {
                id: "1975",
                label: "海珠区",
                value: "440105",
                children: [],
            },
            {
                id: "1976",
                label: "天河区",
                value: "440106",
                children: [],
            },
            {
                id: "1977",
                label: "白云区",
                value: "440111",
                children: [],
            },
            {
                id: "1978",
                label: "黄埔区",
                value: "440112",
                children: [],
            },
            {
                id: "1979",
                label: "番禺区",
                value: "440113",
                children: [],
            },
            {
                id: "1980",
                label: "花都区",
                value: "440114",
                children: [],
            },
            {
                id: "1981",
                label: "南沙区",
                value: "440115",
                children: [],
            },
            {
                id: "1982",
                label: "萝岗区",
                value: "440116",
                children: [],
            },
            {
                id: "1983",
                label: "增城市",
                value: "440183",
                children: [],
            },
            {
                id: "1984",
                label: "从化市",
                value: "440184",
                children: [],
            },
        ],
    },
    {
        id: "1224",
        label: "深圳市",
        value: "440300",
        children: [
            {
                id: "1997",
                label: "罗湖区",
                value: "440303",
                children: [],
            },
            {
                id: "1998",
                label: "福田区",
                value: "440304",
                children: [],
            },
            {
                id: "1999",
                label: "南山区",
                value: "440305",
                children: [],
            },
            {
                id: "2000",
                label: "宝安区",
                value: "440306",
                children: [],
            },
            {
                id: "2001",
                label: "龙岗区",
                value: "440307",
                children: [],
            },
            {
                id: "2002",
                label: "盐田区",
                value: "440308",
                children: [],
            },
            {
                id: "2003",
                label: "龙华区",
                value: "440309",
                children: [],
            },
            {
                id: "2004",
                label: "坪山区",
                value: "440310",
                children: [],
            }
        ],
    },
    {
        id: "1111",
        label: "杭州市",
        value: "330100",
        children: [
            {
                id: "962",
                label: "上城区",
                value: "330102",
                children: [],
            },
            {
                id: "963",
                label: "下城区",
                value: "330103",
                children: [],
            },
            {
                id: "964",
                label: "江干区",
                value: "330104",
                children: [],
            },
            {
                id: "965",
                label: "拱墅区",
                value: "330105",
                children: [],
            },
            {
                id: "966",
                label: "西湖区",
                value: "330106",
                children: [],
            },
            {
                id: "967",
                label: "滨江区",
                value: "330108",
                children: [],
            },
            {
                id: "968",
                label: "萧山区",
                value: "330109",
                children: [],
            },
            {
                id: "969",
                label: "余杭区",
                value: "330110",
                children: [],
            },
            {
                id: "970",
                label: "桐庐县",
                value: "330122",
                children: [],
            },
            {
                id: "971",
                label: "淳安县",
                value: "330127",
                children: [],
            },
            {
                id: "972",
                label: "建德市",
                value: "330182",
                children: [],
            },
            {
                id: "973",
                label: "富阳市",
                value: "330183",
                children: [],
            },
            {
                id: "974",
                label: "临安市",
                value: "330185",
                children: [],
            },
        ],
    },
    {
        id: "810000",
        label: "香港特别行政区",
        value: "8100",
        "children":
            [
                {
                    "id": "810001",
                    "label": "中西区",
                },
                {
                    "id": "810002",
                    "label": "湾仔区",
                },
                {
                    "id": "810003",
                    "label": "东区",
                },
                {
                    "id": "810004",
                    "label": "南区",
                },
                {
                    "id": "810005",
                    "label": "油尖旺区",
                },
                {
                    "id": "810006",
                    "label": "深水埗区",
                },
                {
                    "id": "810007",
                    "label": "九龙城区",
                },
                {
                    "id": "810008",
                    "label": "黄大仙区",
                },
                {
                    "id": "810009",
                    "label": "观塘区",
                },
                {
                    "id": "810010",
                    "label": "荃湾区",
                },
                {
                    "id": "810011",
                    "label": "屯门区",
                },
                {
                    "id": "810012",
                    "label": "元朗区",
                },
                {
                    "id": "810013",
                    "label": "北区",
                },
                {
                    "id": "810014",
                    "label": "大埔区",
                },
                {
                    "id": "810015",
                    "label": "西贡区",
                },
                {
                    "id": "810016",
                    "label": "沙田区",
                },
                {
                    "id": "810017",
                    "label": "葵青区",
                },
                {
                    "id": "810018",
                    "label": "离岛区",
                }
            ],
    }

]

export const cityMap = {
    "北京市": "北京市",
    "上海市": "上海市",
    "广州市": "广东省",
    "深圳市": "广东省",
    "杭州市": "浙江省",
    "香港特别行政区": "香港特别行政区"
}