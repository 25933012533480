export function getTime() {
  return new Date().toLocaleTimeString().substring(0, 2)
}

export function joinPath(...paths) {
  const p = paths.filter(Boolean).map((path) => {
    path = trimLeftBy(path, "/")
    return trimRightBy(path, "/")
  })

  return "/" + p.join("/")
}

export function trimLeftBy(str, substr) {
  if (str.startsWith(substr)) {
    return str.substring(substr.length)
  }

  return str
}
export function trimRightBy(str, substr) {
  if (str.endsWith(substr)) {
    return str.substring(0, str.length - substr.length)
  }

  return str
}

export function dataURLToBlob(dataurl){
  var arr = dataurl.split(',');
  var mime = arr[0].match(/:(.*?);/)[1];
  var bstr = atob(arr[1]);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type:mime});
}