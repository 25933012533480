import request from "../utils/request"

export function getEventList({ page = 1, size = 20, status = 0, timeStatus = 0,city = "",search = "" } = {}) {
  if (timeStatus === "") {
    timeStatus = 0
  }
  return request.get(`/events?page=${page}&size=${size}&status=${status}&timeStatus=${timeStatus}&city=${city}&search=${search}`)
}

export function deleteEventById(eventId) {
  return request.delete(`/events/${eventId}`)
}

export function updateEventById(eventId, body) {
  return request.put(`/events/${eventId}`, body)
}

export function getEventById(eventId) {
  return request.get(`/events/${eventId}`)
}

export function createEvent(body) {
  return request.post(`/events`, body)
}

export function getTopEvents() {
  return request.get("/topEvent")
}

export function setTopEvents(eventIds) {
  return request.put("/config", {
    name: "eventConfig",
    text: eventIds,
  })
}
