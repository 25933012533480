import { getOrganizationById, updateOrganizationById,createOrganization } from "./../../api/organization"
import React, { useCallback, useState } from "react"
import { Button, Cascader, Form, Input, Spin, Space, message } from "antd"
import { useParams,useHistory } from "react-router-dom"
import AsyncContent from "./../../components/container/AsyncContent"
import {hotCity,cityMap} from "../../assets/js/hotCity"

const stringRule = [{ type: "string", required: true, message: "必填!" }]
const arrayRule = [{ type: "array", required: true, message: "必填!" }]

// resToForm 将响应的数据转换为Form组件识别的数据结构
function resToForm(res) {
  const { city, district } = res
  const pca = [ city, district].filter(Boolean)
  return { ...res, pca }
}

export default function OrgDetail() {
  const [submitting, setSubmitting] = useState(false)
  const history = useHistory()
  let { id } = useParams()
  id = id ? Number(id) : 0
  const [form] = Form.useForm()

  const fetchData = useCallback(async () => {
    if (id > 0 ) {
      const res = await getOrganizationById(id)
      form.setFieldsValue(resToForm(res))
      return res
    }
    return {}
  }, [form, id])

  const triggerSubmit = async (isPublish) => {
    try {
      await form.validateFields()
    } catch (error) {
      console.error(error)
      return message.error("请检查表单错误")
    }

    form.submit()
  }

  const submit = async (values) => {
    const { pca = [] } = values
    const [ city = "", district = ""] = pca
    const province = cityMap[city]
    const data = {
      ...values,
      province,
      city,
      district,
    }

    Reflect.deleteProperty(data, "pca")

    setSubmitting(true)

    try {
      if (id >0) {
        await updateOrganizationById(id, data)
      } else {
        const result = await createOrganization(data)
        history.push(`/org/list/${result}`)
        message.success("保存成功")
        return
      }
      message.success("操作成功")
    } catch (error) {
      message.success(error)
    } finally {
      setSubmitting(false)
    }
  }

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  }

  return (
    <AsyncContent fetch={fetchData} defaultData={{}}>
      {({ data = {}, loading }) => (
        <Spin spinning={loading}>
          <div className="detail__topbar sticky flex justify-between bg-white py-2 px-4 border-b z-10">
            <Space>
              <Button loading={submitting} type="primary" onClick={() => triggerSubmit()}>
                保存
              </Button>
            </Space>
          </div>
          <div className="p-4">
            <Form {...layout} layout="horizontal" form={form} onFinish={submit} validateTrigger="onBlur">
              <Form.Item label="名称" name="name" rules={stringRule}>
                <Input className="w-96" placeholder="请输入机构名称" />
              </Form.Item>
              <Form.Item label="活动地点" name="pca" rules={arrayRule}>
                <Cascader
                  options={hotCity}
                  fieldNames={{ value: "label" }}
                  placeholder="请选择城市"
                  style={{ width: "24rem" }}
                />
              </Form.Item>
              <Form.Item label="详细地址" name="address" rules={stringRule}>
                <Input className="w-96 mt-2" placeholder="请输入详细地址"></Input>
              </Form.Item>
            </Form>
          </div>
        </Spin>
      )}
    </AsyncContent>
  )
}
