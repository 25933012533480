import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useAuth } from './../utils/auth'

export default function ProtectedRoute({ children, ...props }) {
  const { token } = useAuth()

  return (
    <Route
      {...props}
      render={({ location }) => (token ? children : <Redirect to={{ pathname: '/login', state: { from: location } }} />)}
    />
  );
}
