import React, { useEffect, useState } from "react"
import E from "wangeditor"

export default function Editor({ value, className, height = 600, onChange }) {
  const [editor, setEditor] = useState(null)
  useEffect(() => {
    const editor = new E("#editor")
    editor.config.menus = ["image"]
    editor.config.zIndex = 1
    editor.config.height = height
    editor.config.pasteFilterStyle = false
    editor.create()
    editor.config.onchange = (newHtml) => {
      onChange(newHtml)
    }
    setEditor(editor)
    return () => {
      editor.destroy()
    }
  }, [])

  if (editor) {
    editor.txt.html(value)
  }

  return <div id="editor" className={className}></div>
}
