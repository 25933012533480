/* eslint-disable eqeqeq */
import {RocketFilled} from "@ant-design/icons"
import {Button, Cascader, Input, message, Popconfirm, Popover, Select, Space, Table} from "antd"
import dayjs from "dayjs"
import {useCallback, useMemo, useState} from "react"
import {Link} from "react-router-dom"
import {createEvent, deleteEventById, getEventList, updateEventById,} from "./../../api/event"
import {areaCity} from "../../assets/js/areaCity"
import AsyncContent from "./../../components/container/AsyncContent"
import "./Event.css"

const {Option} = Select

export default function EventList() {
    const [current, setCurrent] = useState(1)
    const [size, setSize] = useState(20)
    const [reloadFlag, setReloadFlag] = useState(0)
    const [filter, setFilter] = useState({city: ["深圳市"]})

    const pagination = useMemo(
        () => ({
            current: current,
            pageSize: size,
        }),
        [current, size]
    )

    const reloadList = () => {
        setReloadFlag(reloadFlag + 1)
    }

    const fetchData = useCallback(async () => {
        const {isRecommended, timeStatus, city = [], search} = filter
        const res = await getEventList({
            page: pagination.current,
            size: pagination.pageSize,
            status: (isRecommended && isRecommended[0]) || 0,
            timeStatus: timeStatus || 0,
            city: city[1] || city[0],
            search: search
        })
        return res
    }, [pagination, filter])

    const onTableChange = ({current, pageSize}, filters) => {
        setSize(pageSize)
        setCurrent(current)
        setFilter({
            ...filter,
            ...filters,
        })
    }

    const handleWeightChange = async (e, row) => {
        const weight = Number(e.target.value)
        if (weight === row.weight) {
            return
        }

        try {
            await updateEventById(row.id, {
                weight: Number(weight),
            })

            message.success("操作成功")
            reloadList()
        } catch (error) {
            message.error(error)
        }
    }

    // 更新发布状态
    const changeStatus = async (row) => {
        try {
            await updateEventById(row.id, {isRecommended: !row.isRecommended})
            reloadList()
            message.success("操作成功")
        } catch (error) {
            console.error(error)
        }
    }

    // 删除展览
    const deleteEvent = async (row) => {
        try {
            await deleteEventById(row.id)
            reloadList()
            message.success("操作成功")
        } catch (error) {
            console.error(error)
        }
    }

    const columns = [
        {
            title: "标题",
            dataIndex: "name",
            key: "name",
            ellipsis: true,
            width: 220,
            render: (text, row) => <Link target="_blank" to={`/events/list/${row.id}`}>{text}</Link>,
        },
        {
            title: "副标题",
            dataIndex: "subname",
            ellipsis: true,
            key: "subname",
            width: 220,
        },
        {
            title: "来自",
            dataIndex: "sourceName",
            key: "sourceName",
            width: 80,
        },
        {
            title: "权重",
            key: "weight",
            editable: true,
            dataIndex: "weight",
            width: 120,
            render: (value, row) => (
                <Input
                    defaultValue={value}
                    placeholder="排序权重"
                    bordered={false}
                    onBlur={(e) => handleWeightChange(e, row)}
                />
            ),
        },
        {
            title: "状态",
            dataIndex: "isRecommended",
            key: "isRecommended",
            width: 120,
            filters: [
                {text: "草稿", value: "1"},
                {text: "已发布", value: "2"},
            ],
            filterMultiple: false,
            render: (value) =>
                value ? (
                    <span>
            已发布 <RocketFilled className="text-green-500"/>
          </span>
                ) : (
                    "草稿"
                ),
        },
        {
            title: "最近更新",
            dataIndex: "updatedAt",
            key: "updatedAt",
            width: 220,
            render: (value) => {
                return dayjs(value).format("YYYY年MM月DD日 HH时mm分")
            },
        },
        {
            title: "操作",
            key: "action",
            fixed: "right",
            render: (_, row) => (
                <Space size="middle">
                    <Button type="link" size="small" onClick={() => changeStatus(row)}>
                        {row.isRecommended ? "下架" : "发布"}
                    </Button>
                    <Popconfirm title="确认删除?" okText="删除" cancelText="达咩🙅" onConfirm={() => deleteEvent(row)}>
                        <Button type="link" danger size="small">
                            删除
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    const [newEventName, setNewEventName] = useState("")
    const createEventAndEdit = async () => {
        try {
            const data = await createEvent({name: newEventName})
            window.open(`./#/events/list/${data}`, "_blank")
            reloadList()
            setNewEventName("")
        } catch (error) {
            console.error(error)
        }
    }

    const handleStatusChange = (value) => {
        setFilter({
            ...filter,
            timeStatus: value,
        })
    }

    const handleCityChange = (value) => {
        setFilter({
            ...filter,
            city: value,
        })
    }

    const onSearch = (value) => {
        setFilter({
            ...filter,
            search: value,
        })
    }

    // timeStatus=1 //1未开始 2进行中 3已结束
    const Filters = (
        <div>
            <Select
                value={filter.timeStatus}
                style={{width: 120}}
                onChange={handleStatusChange}
                placeholder="进行状态"
                allowClear
            >
                <Option value="1">未开始</Option>
                <Option value="2">进行中</Option>
                <Option value="3">已结束</Option>
            </Select>
            <Cascader
                options={areaCity}
                fieldNames={{value: "label"}}
                placeholder="请选择城市"
                style={{width: 150}}
                onChange={handleCityChange}
                showSearch
                value={filter.city}
            />
        </div>
    )

    return (
        <AsyncContent fetch={fetchData} reloadFlag={reloadFlag}>
            {({data = {}, loading}) => (
                <div className="p-4">
                    <div className="mb-2 flex justify-between">
                        <Space>
                            <Link target="_blank" to={`/events/add`} style={{color:'#1890ff',textDecoration:'underline'}}>新增展览</Link>
                        </Space>
                        <Space>
                            {Filters}
                            <Input.Search placeholder="标题搜索" className="w-60" onSearch={onSearch}/>
                        </Space>
                    </div>
                    <Table
                        className="w-full"
                        loading={loading}
                        columns={columns}
                        scroll={{x: 1200}}
                        dataSource={data?.list}
                        pagination={{
                            ...pagination,
                            total: data?.count,
                            showTotal: (t) => `共${t}条`,
                            size: "default",
                            position: ["bottomCenter"],
                        }}
                        size="small"
                        rowKey="id"
                        bordered
                        onChange={onTableChange}
                    />
                </div>
            )}
        </AsyncContent>
    )
}
