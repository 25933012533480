import React, { createContext, useContext, useState } from "react"
import { login } from "./../api/adminUser"

const context = createContext()

export function setLocalToken(token) {
  return localStorage.setItem("CH_TOKEN", token)
}

export function getLocalToken() {
  return localStorage.getItem("CH_TOKEN")
}

export function removeLocalToken() {
  return localStorage.removeItem("CH_TOKEN")
}

export const useAuth = () => {
  return useContext(context)
}

export function PriovideAuth({ children }) {
  const auth = useProvideAuth()
  return <context.Provider value={auth}>{children}</context.Provider>
}

export function useProvideAuth() {
  const [token, setToken] = useState(getLocalToken())

  const loginUser = async (username, password) => {
    const res = await login(username, password)
    setLocalToken(res.token)
    await setToken(res.token)
  }

  const logout = async () => {
    removeLocalToken()
    await setToken(null)
  }

  return {
    token,
    login: loginUser,
    logout,
  }
}
